html {
    height: 100%;
}

body {
    margin: 0;
    height: 100%;
}

:root {
    --fc-button-bg-color: #000000;
    --fc-button-border-color: #000000;
    --fc-button-hover-bg-color: #3d3d3d;
    --fc-button-hover-border-color: #000000;
    --fc-button-active-bg-color: #3d3d3d;
    --fc-button-active-border-color: #000000;
}
